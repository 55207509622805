.table {
  color: #303030;
}

.header {
  font-size: 1rem;
  font-weight: 600;
}

.labelCol {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.color {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.25rem;
}
