@use '../../../../scss/bs.module';

.legend {
  display: flex;

  @include bs.media-breakpoint-down(sm) {
    flex-wrap: wrap;
    row-gap: 0.5rem;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  padding: 0 1.125rem;
}

$size: 0.875rem;
.color {
  min-width: $size;
  width: $size;
  min-height: $size;
  height: $size;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
  text-transform: none;
  font-weight: 500;
}

.tick {
  width: 60%;
  stroke-width: 3px;
}
