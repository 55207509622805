.card {
  padding: 1.5rem;

  flex-direction: row;
  align-items: center;
  gap: 1.75rem;

  height: 100%;
}

.text {
  //height: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  color: var(--app-color-text);
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
}

.desc {
}

.value {
  background-color: #f4f9ff;

  font-size: 3.25rem;
  font-weight: 700;
  color: #4480cb;

  text-align: center;
  height: 4.25rem;
  line-height: 4.25rem;
  min-width: 10.5rem;
  max-width: 10.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.percent {
  font-size: 0.45em;
}

.empty {
  //font-size: 3.25rem;
  font-weight: 400;
  color: #7b7b7b;
}
