.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.5rem;
}

.mainStat {
  flex: auto;
  max-width: 50%;
}

.title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
