.comparativeSection {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  .statsSection {
    min-width: 25%;
    max-width: 25%;

    border-right: 1px solid #e3e3e3;

    padding: 2.5rem 2.75rem;

    display: flex;
    flex-direction: column;
    gap: 3rem;

    .stat {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .label {
        font-size: 1rem;
        color: #666666;
      }

      .value {
        font-weight: 600;
        font-size: 2rem;
      }
    }

    .positive {
      color: #29a32e;
      font-weight: 700;
    }

    .negative {
      color: #666666;
      font-weight: 700;
    }
  }

  .chartSection {
    flex: auto;
    padding: 0 0 1.5rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .chart {
    width: 100%;
    height: 22.5rem;
  }
}
