.toggle {
  min-height: 2.5rem;
  padding: 0.25rem 0.75rem;
  gap: 1rem;

  fill: #303030;

  font-size: 0.8125rem;
  line-height: 1;
}

.icon {
  min-width: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
}

.selectedContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.1875rem;
}

.annotationText {
  font-size: 0.6875rem;
  font-weight: 300;
  color: #7a7777;
}

.menu {
  width: 100%;
  min-width: 100%;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 11px 20px rgba(0, 0, 0, 0.06), 0 7.12963px 11.713px rgba(0, 0, 0, 0.0455556),
    0 4.23704px 6.37037px rgba(0, 0, 0, 0.0364444), 0 2.2px 3.25px rgba(0, 0, 0, 0.03),
    0 0.896296px 1.62963px rgba(0, 0, 0, 0.0235556), 0 0.203704px 0.787037px rgba(0, 0, 0, 0.0144444);
  padding: 0.5rem 0;

  max-height: 8rem;
  overflow-x: hidden;
  overflow-y: auto;

  .item {
    width: fit-content;
    padding: 0.625rem 1rem;
    margin: auto;

    color: #959595;

    font-size: 0.8125rem;
    line-height: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
  }

  .divider {
    margin: 0 0.75rem;
  }
}

.placeholder {
  width: 4.375rem;
}
