.cards {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.legendTitle {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.cashFlowChart {
  height: 20rem;
}
