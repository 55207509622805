.filler {
  flex: 1 0 30%;
}

.card {
  padding: 1.5rem;

  gap: 0.5rem;

  font-size: 1rem;
  white-space: nowrap;
}

.acc {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.accNum {
  color: #666666;

  min-height: 1em;
}

.amount {
  font-size: 2.25rem;
  font-weight: 600;
  color: #303030;
}

.date {
  color: #666666;
}
