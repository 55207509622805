.card {
  overflow: hidden;
  white-space: nowrap;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 2.5rem;
  gap: 2.5rem;
}

.title {
  font-size: 1.5rem;
}

.total {
  font-size: 2rem;
  font-weight: 600;
  max-width: 50%;
}

.body {
  display: flex;
  flex-direction: row;
}

.item {
  flex: 1;

  position: relative;
  display: flex;
  flex-direction: column;

  padding: 1rem 1.25rem;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0.5rem;
    right: 0;
    height: calc(100% - 1rem);
    width: 1.1px;
    background-color: #e3e3e3;
    display: block;
  }
}

.color {
  height: 0.25rem;
  width: 2.5rem;
}

.value {
  font-size: 1.5rem;
  font-weight: 600;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.label {
  color: #9a9a9a;
}
