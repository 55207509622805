.topbar {
  width: 100%;
  height: 6.875rem;

  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.04), 0 0.4px 6.5px rgba(0, 0, 0, 0.02);

  display: flex;
  align-items: center;
  padding: 1.375rem 2rem 1rem;
}

.pageInfo {
  flex: auto;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .breadcrumb {
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #7a7777;

    min-height: 1rem;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25rem;

    .separator {
      transform: rotate(-90deg);
      width: 0.625rem;
      height: 0.375rem;

      stroke: #6d7172;
    }
  }

  .title {
    font-size: 1.625rem;
    font-weight: 600;
  }

  .lastSync {
    font-size: 0.75rem;
    font-weight: 400;
    color: #929292;

    .value {
      font-weight: 500;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1.5rem;

  .btn {
    padding: 0.625rem 1.5rem;
    font-size: 0.8125rem;
    line-height: 1;

    .icon {
      min-width: 0.75rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .download {
    .text {
      margin-top: 0.125rem;
    }
  }

  .notification {
    min-width: 1.25rem;
    width: 1.25rem;
    height: 1.5rem;
    padding: 0;
  }
}
