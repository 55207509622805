$enable-caret: false;
$font-size-base: 0.8125rem;
$line-height-base: 1;
$h1-font-size: 2rem;

$card-border-width: 1px;
$card-border-color: #e1e1e1;
$card-border-radius: 0.625rem;
$card-spacer-y: 1rem;
$card-spacer-x: 1.25rem;

$input-font-size: 1rem;
$input-border-width: 1.5px;
$input-border-color: #959595;
$input-focus-border-color: var(--app-color-primary);
$input-focus-box-shadow: none;
$input-padding-x: 0;
$input-padding-y: 1rem;
$input-placeholder-color: #959595;

$form-floating-height: 4.75rem;
$form-floating-line-height: 1;
$form-floating-label-transform: translateY(-2rem);
$form-floating-label-opacity: 1;
$form-floating-transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
$form-floating-input-padding-t: 0.625rem;

$link-color: #303030;
$link-decoration: none;
