.dropdownToggle {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  .content {
    flex: auto;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  .icon {
    stroke: currentColor;
    height: 0.4375em;
    width: auto;
  }
}
