@import 'src/scss/variables.module';

.card {
  width: 34.875rem;
}

.cardBody {
  padding-left: 2.375rem;
  padding-right: 2.375rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.chart {
  width: 5.625rem;
  height: 5.625rem;
}

.detailWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .label {
    font-size: 1.125rem;
  }

  .amount {
    font-size: 2rem;
    font-weight: 600;
    min-width: 7.5rem;
    max-width: 7.5rem;
    text-align: center;
  }
}

.titleWrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .title {
    color: #2557a3;
    font-size: 1rem;
    font-weight: 600;
    margin: 0.5rem 0;
  }
}

.compareWrap {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  font-size: 0.75rem;
}

.link {
  padding: 0.625rem;
  width: fit-content;

  font-weight: 600;
  color: var(--app-color-primary);
  border-color: var(--app-color-primary);
  border-width: 1px;
}

.icon {
  //margin-top: 2.325rem;
  width: 1rem;
  height: 1rem;
}
