.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  width: calc(100% + 1.5rem);
}

.card {
  flex: 0 0 calc(50% - 1.5rem);
}
