@use 'src/scss/variables.module';

.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
}

.transparent {
  background-color: transparent;
  border: none;

  &:focus {
    outline: 1px solid black;
    border: none;
    background-color: transparent;
    color: initial;
    box-shadow: none;
  }

  &:active,
  &:hover {
    background-color: transparent;
    color: initial;
  }
}

.primary {
  background-color: variables.$app-color-primary;
}

.outline {
  background-color: transparent;
  border: 1px solid #e3e3e3;

  &:active,
  &:hover {
    background-color: transparent;
    color: initial;
    border-color: darken(#e3e3e3, 20%);
  }
}
