@use 'src/scss/bs.module';

.sidebar {
  min-width: 17.5rem;
  max-width: 17.5rem;
  height: 100vh;

  background-color: #f1f6f7;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem 1.25rem;

  .nav {
    flex: auto;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.link {
  padding: 0;
}

.subMenu {
  margin-top: 0.75rem;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  min-height: 2.65rem;
  padding: 0.75rem 1.25rem;

  color: #8d949e;
  font-size: 0.75rem;

  .icon {
    min-width: 1.125rem;
    width: 1.125rem;
    height: 1.125rem;
    fill: #8d949e;
    transition: fill 0.15s ease-in-out;
  }

  .content {
    flex: auto;
  }

  .caret {
    min-width: 0.75rem;
    width: 0.75rem;
    height: 0.5rem;
    stroke: #8d949e;
    transition: transform 0.2s ease-in-out;

    &.collapsed {
      transform: rotate(-90deg);
    }
  }

  &.item {
    font-weight: 600;
  }

  &:hover,
  &:focus {
    color: black;

    .icon {
      fill: black;
    }

    .caret {
      stroke: black;
    }
  }

  &.active {
    font-weight: 600;

    &.item {
      color: black;
      background-color: white;
      border-radius: 0.25rem;
    }

    &.subItem {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 20.27%,
        rgba(255, 255, 255, 0.8) 53.17%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 6px;
      color: #2557a3;
    }

    .icon > svg {
      fill: var(--app-color-primary);
    }
  }
}
