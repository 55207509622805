/* light */
@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-ExtraLight.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-ExtraLight.woff') format('woff');
}

/* regular */
@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Regular.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Regular.woff') format('woff');
}

/* medium */
@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-SemiBold.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-SemiBold.woff') format('woff');
}

/* semi bold */
@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Bold.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Bold.woff') format('woff');
}
