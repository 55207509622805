.panel {
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;

  th {
    padding: 0;
  }

  tbody {
    display: block;
    overflow: auto;
    height: 72vh;
  }

  tbody > tr:first-of-type > td > * {
    margin-top: 1rem;
  }

  tbody > tr:last-of-type > td > * {
    margin-bottom: 1rem;
  }
}

.header {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  border-bottom: 1px solid #e3e3e3;

  .title {
    padding: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.search {
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;

  padding: 1.25rem 1rem;

  display: flex;
  flex-direction: row;
  gap: 1rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  input {
    flex: auto;
    border: none;
    font-size: 1rem;
    color: #838383;

    &:focus {
      outline: none;
    }
  }
}

.cell {
  padding: 0 1rem;
}

.groupHeader {
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #a0a0a0;
}

.value {
  padding: 0.75rem 1rem;
  width: 100%;
  border-radius: 0.25rem;
  text-align: start;
  line-height: 1.25rem;

  &:hover {
    background: #f5f5f5;
  }

  &.active {
    background: #f1f1f1;
  }
}
