.page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: stretch;
}

.descCard {
  background: #f8f8f8;

  :global {
    .card-title {
      font-weight: 600;
      font-size: 1rem;
    }

    .card-text {
      line-height: 1.25rem;
    }
  }
}

.metrics {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.total {
  :global {
    .card-body {
      display: flex;
      flex-direction: column;
    }

    .card-title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .card-text {
      flex: auto;
      display: flex;
      align-items: center;

      color: #4480cb;
      font-size: 4.25rem;
      font-weight: 700;
    }
  }
  flex: 20%;
}

.graph {
  flex: 80%;
  height: 11.5rem;
}
