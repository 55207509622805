.menu {
  max-width: 100%;
  width: 100%;

  .menuItem {
    overflow: hidden;
    text-overflow: ellipsis;
    @extend .companyName;
  }
}

.selected {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: none;

  .appLogo {
    min-width: 2.625rem;
    width: 2.625rem;
    height: 2.625rem;
  }

  .selectedDetails {
    flex: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    overflow: hidden;

    .appName {
      font-size: 1rem;
      font-weight: 700;
      color: #494949;
    }

    .companyName {
      color: #858b8b;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.companyName {
  font-size: 0.75rem;
}
