.doughnutCard {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;

  .chart {
    min-width: 40%;
    max-width: 40%;
    padding: 2rem;
    height: 14.5rem;
    border-right: 1px solid #e3e3e3;
  }

  .tableSection {
    flex: auto;
    display: flex;
    justify-content: center;
    padding: 1.5rem;

    .table {
      min-width: 80%;
    }
  }
}
