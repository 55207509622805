.title {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}

.top {
  display: flex;
  gap: 1.5rem;
  justify-content: stretch;

  .title {
    margin-top: 0;
  }
}

.doughnut {
  flex: 1 0 30%;
  display: flex;
  flex-direction: column;

  .card {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .chart {
      height: 80%;
      width: 100%;
    }
  }
}

.legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar {
  flex: 1 0 65%;

  .chart {
    height: 17.5rem;
  }
}

.status {
  font-weight: 600;

  &.unpaid {
    color: #bc4749;
  }

  &.partiallyPaid {
    color: #f4ac45;
  }

  &.overpaid {
    color: #294691;
  }

  &.fullyPaid {
    color: #06a77d;
  }
}
