.root {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
}

.right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page {
  max-height: calc(100vh - 6.875rem);
  overflow: auto;
  padding: 2rem;
  flex-grow: 1;

  .content {
    max-width: 1140px;
    min-height: 100%;
  }
}
