.wrapper {
  position: relative;

  .floating {
    > label {
      color: #959595;
      font-size: 1rem;
      padding: 0.625rem 0;
    }

    .formControl {
      margin-top: 1.5rem;

      &:focus ~ label {
        color: var(--app-color-primary);
      }
    }
  }

  input.formControl {
    height: 2.25rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;

    padding-right: 1.25rem;

    &:not(:placeholder-shown) {
      & ~ .btn {
        display: block;
      }
    }
  }

  .btn {
    display: none;
    position: absolute;
    padding: 0;

    right: 0;
    bottom: 0.625rem;

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }
}

$error-color: #f44336;

.error {
  .floating {
    > label {
      color: $error-color;
    }

    .formControl:focus ~ label {
      color: $error-color;
    }
  }

  input.formControl {
    border-color: $error-color;
  }
}

.message {
  color: $error-color;
  padding-top: 0.5rem;
}
