@use '../../scss/bs.module';

.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.metricWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .metricCards {
    display: flex;
    gap: 1.5rem;
    flex-direction: row;
  }

  .profitMetric {
    @include bs.media-breakpoint-up(sm) {
      width: 20%;
    }
    @include bs.media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.revenueTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;

  @include bs.media-breakpoint-up(sm) {
    flex-direction: row;
  }
  @include bs.media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.revenueChart {
  width: 100%;
  height: 27rem;
}
