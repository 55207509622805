.card {
  padding: 1rem 1.75rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.title {
  font-size: 1rem;
  color: #303030;
}

.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2.5rem;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 1rem;
  color: #666666;
  white-space: nowrap;
}

.value {
  font-size: 2rem;
  font-weight: 600;
  color: #303030;
}

.mainStat {
}

.secondaryStat {
  .value {
    font-size: 1rem;
    line-height: 2rem;
  }
}

.standard {
  .separator {
    width: 1px;
    border-radius: 2px;
    background-color: #e1e1e1;
  }
}

.cashFlow {
  &.card {
    padding: 1.75rem;
    justify-content: normal;
    gap: 2rem;
  }

  .stats {
    align-items: center;
    flex: 1;
    justify-content: space-between;
  }

  .title {
    font-size: 1.5rem;
  }

  .mainStat {
    .value {
      font-size: 3rem;
    }
  }

  .secondaryStat {
    .label {
      color: #959595;
      font-size: 0.875rem;
    }

    .value {
      line-height: 1rem;
      text-align: end;
    }
  }
}
