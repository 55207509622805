.wrap {
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.errorWrap {
  max-height: 40%;
  max-width: 80%;

  background-color: #ffe6e6;

  border-radius: 20px;
  overflow: hidden;
}

.error {
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 32px;
}
