.title {
  margin-bottom: 1rem;

  &:nth-of-type(n + 2) {
    margin-top: 3rem;
  }
}

.chartWrap {
  width: 100%;
  display: flex;
  align-items: center;
}

.chart {
  flex: 1 0 35%;
  height: 100%;
}

.legend {
  flex: 1 0 65%;
}

.clientDetails {
  padding: 1.5rem 0.5rem;

  .statsTable {
    tr {
      td {
        padding: 0.5rem 0;
      }

      & > :first-child {
        color: #9a9a9a;
        padding-left: 0;
        padding-right: 1.5rem;
      }

      & > :nth-child(2) {
        font-weight: 600;
      }

      &:nth-last-of-type(1) > td {
        padding-top: 0.75rem;
      }

      &:nth-last-of-type(2) > td {
        padding-bottom: 0.75rem;
      }

      &:last-of-type {
        border-top: 1px solid #e3e3e3;
      }
    }
  }

  h2 {
    margin: 2rem 0;
  }
}
