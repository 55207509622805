.page {
  display: flex;
  height: 100vh;
  background-color: #2557a3;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;

    .logoText {
      font-size: 32px;
      font-weight: 700;
      color: white;
    }
  }

  .content {
    width: 60%;
    max-width: 850px;
    min-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5rem;

    .formPanel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: white;
      border-radius: 1.25rem;

      padding: 2rem 1.5rem;
      box-sizing: border-box;

      width: 100%;
      min-height: 65vh;
      max-width: 850px;
      height: 65vh;

      .title {
        margin-top: 0;
        font-weight: 700;
      }

      .form {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 60%;
        min-height: 270px;

        .error {
          background-color: #f7f1f1;
          padding: 1rem;
          margin: 1rem 0;
          border-radius: 10px;
        }

        .input {
          min-width: 100%;
        }

        .loginButton {
          margin-top: 2rem;
          min-width: 75%;

          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1.5rem;

          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }
}
