.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: #303030;
  font-weight: 600;

  height: 1.75rem;

  .icon {
    min-height: 100%;
    max-height: 100%;
  }
}
