.panel {
  border: 1px solid #e3e3e3;
  border-radius: 0.625rem;
  overflow: hidden;
  font-size: 0.8125rem;
  white-space: nowrap;
}

.table {
  margin: 0;

  .tbody {
    border-top: none;
  }

  th {
    color: #303030;
    padding: 2rem 1rem;
    font-weight: 600;
  }

  td {
    color: #303030;
    padding: 1.25rem 1rem;
    white-space: nowrap;
  }

  tr {
    & > :first-child {
      padding-left: 2.5rem;
    }

    & > :last-child {
      padding-right: 2.5rem;
    }

    &.expanded {
      background: #efefef;
    }
  }

  .subRow {
    background-color: #fafafa;
    padding: 0;

    &:not(.expanded) {
      border: none;
    }
  }

  & > :not(:first-child) {
    border-top: 1px solid #e3e3e3;
  }

  tfoot {
    font-weight: 600;
    td {
      border: none;
    }
  }
}

.striped {
  --bs-table-striped-bg: #f8f8f8;

  .tbody {
    border-top: 1px solid #e3e3e3;
  }
}

.subTable {
  border: none;
  border-radius: 0;

  th {
    padding: 0 0.5rem 0.5rem 0.5rem;
    color: #9a9a9a;
  }

  tr {
    & > :first-child {
      padding-left: 0.5rem;
    }

    & > :last-child {
      padding-right: 0.5rem;
    }
  }

  tbody {
    color: black;
  }

  td {
    padding: 0.75rem 0.5rem;
  }
}

.statement {
  th {
    padding: 2rem 3rem;
  }

  td {
    padding: 0.25rem 3rem;
  }

  .firstCol {
    width: 100%;
    left: 0;
    position: sticky;
    background: white;

    .textWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .lastCol {
    right: 0;
    position: sticky;
    background: #f7fafa;
  }

  .dataCol {
    text-align: right;
  }

  .topRow {
    padding: 1rem 3rem;

    > :not(.dataCol) > span {
      font-weight: 600;
    }

    .secondaryText {
      font-weight: 400;
      font-size: 0.9em;
    }

    border-top: 1px solid #e3e3e3;
    margin-top: 0.5rem;
  }

  .totalsRow {
    background-color: #eef4f5;
    //background-color: #f0f0f04d;
  }

  .lastSubRow {
    padding-bottom: 1rem;
  }

  .particularName {
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 1.5rem;

    .indicator {
      margin-left: -1.25rem;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;

      text-align: center;
      line-height: 1.5rem;
      font-size: 1.5em;
      font-weight: normal;

      > svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    > svg {
      fill: var(--app-color-primary);
    }
  }
}

.fullWidth {
  .table {
    table-layout: fixed;
    width: 100%;

    th {
      white-space: break-spaces;
      width: 10%;
    }

    td {
      white-space: break-spaces;
      width: 10%;
    }
  }
}
