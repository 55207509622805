.page {
  display: flex;
  flex-direction: row;
}

.list {
  flex: 1 0 22%;
}

.detail {
  flex: 1 0 75%;
  padding: 1.25rem 2rem;
  width: calc(100% - 4rem);

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-weight: 600;
    font-size: 1.25rem;
    color: #2557a3;
  }

  .period {
    font-size: 1rem;
    color: #9a9a9a;
  }

  .detailList {
    display: flex;
    flex-wrap: wrap;

    margin-top: 1.5rem;
    margin-bottom: 3rem;

    .label {
      flex: 1 0 18%;
      padding: 0.5rem 0;
      font-size: 1rem;
      color: #9a9a9a;
    }

    .value {
      flex: 1 0 82%;
      padding: 0.5rem 0;
      font-size: 1rem;
      font-weight: 600;
      color: #000000;
    }
  }

  td.narrationCol,
  th.narrationCol {
    width: 20%;
  }

  .voucherSubTable {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
