$app-font-family: 'Clarity City', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$app-color-text: #393939;
$app-color-background: #ffffff;
$app-color-primary: #2557a3;

:export {
  --app-font-family: #{$app-font-family};
  --app-color-text: #{$app-color-text};
  --app-color-background: #{$app-color-background};
  --app-color-primary: #{$app-color-primary};
}
