@use 'scss/variables.module';

//noinspection CssInvalidAtRule
@import-sanitize;

@import 'assets/fonts/clarity-city-1.0.0/css/bundles/default.css';
@import 'assets/fonts/Metropolis-r11/css/bundles/default.css';

@import 'scss/bs.custom';
@import '~bootstrap/scss/bootstrap';

:root {
  --app-font-family: #{variables.$app-font-family};
  --app-color-text: #{variables.$app-color-text};
  --app-color-background: #{variables.$app-color-background};
  --app-color-primary: #{variables.$app-color-primary};

  --bs-body-font-family: var(--app-font-family);
  --bs-body-color: var(--app-color-text);
  --bs-body-bg: var(--app-color-background);
  --bs-primary: var(--app-color-primary);
  --bs-body-line-height: 1;

  /* padding for container at different screen sizes */
  @include media-breakpoint-up(xxl) {
    --bs-gutter-x: 0.625rem;
  }

  @include media-breakpoint-between(sm, xxl) {
    --bs-gutter-x: 3.125rem;
  }

  @include media-breakpoint-down(sm) {
    --bs-gutter-x: 1rem;
  }
}

* {
  /* Works on Firefox */
  scrollbar-width: thin;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 20px;
  }
}

body {
  //letter-spacing: 0.05rem;

  /* minimum body widths */
  @include media-breakpoint-between(sm, lg) {
    min-width: map-get($grid-breakpoints, lg);
    overflow-x: auto;
  }

  @include media-breakpoint-down(sm) {
    min-width: 320px;
    overflow-x: auto;
  }
}

.row {
  --bs-gutter-x: 0;
}

h1 {
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0;
}
