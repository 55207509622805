.card {
  width: 16.6875rem;
}

.primaryWrap {
  display: flex;
  justify-content: space-between;

  .primaryData {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 0.5rem;
      color: #2557a3;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .primaryPeriod {
      color: #666666;
      font-size: 1.125rem;
      margin-bottom: 0.5625rem;
    }
  }

  .icon {
    padding: 0;
    height: fit-content;

    svg {
      width: 1.625rem;
      height: 1.625rem;
    }
  }
}

.primaryValue {
  color: #303030;
  font-size: 1.6875rem;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.secondaryWrap {
  margin-top: 1rem;

  color: #666666;
  font-size: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .secondaryValue {
    display: flex;
    justify-content: space-between;
  }
}
